// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-example-js": () => import("./../../../src/pages/example.js" /* webpackChunkName: "component---src-pages-example-js" */),
  "component---src-pages-indeed-html-js": () => import("./../../../src/pages/indeed.html.js" /* webpackChunkName: "component---src-pages-indeed-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-receipt-js": () => import("./../../../src/pages/receipt.js" /* webpackChunkName: "component---src-pages-receipt-js" */)
}

